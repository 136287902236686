import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import ContainerWithBg from "../common/helpers/ContainerWithBg.js"
import Container from "../common/helpers/Container.js"
import ContatoSocialMedia from "../common/elements/ContatoSocialMedia.js"
import ContatoPin from "../common/icons/ContatoPin.js"
import ContatoEmail from "../common/icons/ContatoEmail.js"
import ContatoSmartphone from "../common/icons/ContatoSmartphone.js"
import ContatoWhatsapp from "../common/icons/ContatoWhatsapp.js"

import { StaticImage } from "gatsby-plugin-image"

import Page from "../common/elements/Page.js"
import FormContato from "../common/elements/FormContato.js"


export default function ContatoPage({data}){

  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/contato-hero.png"></link>
      </Helmet>
      <ContainerWithBg 
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-contato-hero"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1 feature-image"
      >
        <h1 className="h1-egg mb-15r">Contato</h1>
      </ContainerWithBg>
      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <FormContato />
          </div>
          <div className="col-md-6 p-0 bg-color-golden dfc">            
            <div className="px-2 py-4 px-md-4 py-md-6 p-lg-6">
              <ul className="list-medium list-unstyled pb-2">
                <li className="d-flex">
                  <ContatoPin />
                  <address className="ps-3">
                    Rua Grajaú <br />
                    Cep: 20561-148 <br />
                    Rio de Janeiro
                  </address>
                </li>
                <li><ContatoEmail /> <a href="mailto:info@comunicacaointegrada.com.br" className="ps-3 c-purple">info@comunicacaointegrada.com.br</a></li>
                <li><ContatoSmartphone /> <span className="ps-3">(21) 2038-4595</span></li>
              </ul>
              <ContatoSocialMedia />
            </div>
            
            <div className="video-container h-100">
              <StaticImage src="../images/map.png" className="h-100" alt="mapa" />
              {/* <iframe title="map" className="w-100 h-100 align-self-stretch" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.6970588662907!2d-43.26693958439747!3d-22.92454204426973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997dee28361f69%3A0x81c1d37287267788!2sR.%20Graja%C3%BA%2C%20220%20-%20Grajau%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2020561-148!5e0!3m2!1spt-BR!2sbr!4v1626408065218!5m2!1spt-BR!2sbr" width="600" height="450" allowFullScreen="" loading="lazy"></iframe> */}
            </div>
          
            
          </div>
        </div>
      </Container>
      
    </Page>
  )
}

export const pageQuery = graphql`
  {
    wpPage(databaseId: {eq: 19036}) {
      nodeType
      title
      uri
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
    }
  }
`